<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog
      v-model="modalAction"
      max-width="800px"
      scrollable
      @keydown="executeCloseModalDeliveryRequests"
      @click:outside="executeCloseModalDeliveryRequests"
    >
      <v-card>
        <div class="p-4 pb-0">
          <h5>
            {{
              `Despachar ${
                requestsToDelivery.length ? "Solicitudes" : "Materiales"
              }`
            }}
          </h5>
        </div>
        <hr />
        <v-card-text>
          <v-container class="pt-0">
            <v-form @submit.prevent="sendUpdateDeliveryRequestsOrMaterials">
              <v-row align-content="center">
                <v-col cols="12" md="6" sm="12" class="mt-0">
                  <label class="mt-1">
                    Tipo de Despacho *
                  </label>
                  <v-select
                    v-model="$v.form.received_type.$model"
                    :error="$v.form.received_type.$invalid && submitUpload"
                    :error-messages="
                      $v.form.received_type.$invalid && submitUpload
                        ? 'Este campo es requerido'
                        : null
                    "
                    item-text="name"
                    item-value="value"
                    :items="TYPE_REQUESTS_DISPATCH"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="mt-0">
                  <div v-if="form.received_type">
                    <label for="comments" class="mt-1">
                      {{
                        form.received_type == TYPE_REQUEST_TRANSFER
                          ? "Quien Recibe"
                          : "Quien Retira"
                      }}
                      *
                    </label>
                    <v-text-field
                      :placeholder="
                        form.received_type == TYPE_REQUEST_TRANSFER
                          ? 'Ingresa quien recibe'
                          : 'Ingresa quien retira'
                      "
                      v-model="$v.form.user.$model"
                      :error="$v.form.user.$invalid && submitUpload"
                      :error-messages="
                        $v.form.user.$invalid && submitUpload
                          ? 'Este campo es requerido'
                          : null
                      "
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0">
                  <label for="comments" class="mt-2 mb-0">
                    Fecha de Entrega *
                  </label>
                  <v-menu
                    ref="menu"
                    v-model="datepicker_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="delivery_date_formatted"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :error="$v.form.delivery_date.$invalid && submitUpload"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="$v.form.delivery_date.$model"
                      scrollable
                      locale="es"
                      :min="minDateDelivery"
                      @input="datepicker_menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pt-0">
                  <label for="comments" class="mt-2 mb-0">
                    Hora de Entrega *
                  </label>
                  <v-menu
                    ref="timepicker_menu"
                    v-model="timepicker_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.delivery_time"
                        prepend-icon="mdi-clock"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :error="$v.form.delivery_time.$invalid && submitUpload"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="$v.form.delivery_time.$model"
                      locale="es"
                      full-width
                      @click:minute="
                        $refs.timepicker_menu.save(form.delivery_time)
                      "
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="pt-0">
                  <label for="comments" class="mt-0">
                    Observaciones
                  </label>
                  <v-textarea
                    rows="4"
                    counter
                    outlined
                    hide-details
                    placeholder="Ingresa aquí a las consideraciones a tener en cuenta para el despacho"
                    v-model="form.comment"
                  ></v-textarea>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  class="mt-0 pb-0"
                  v-if="form.received_type == TYPE_REQUEST_TRANSFER"
                >
                  <label for="comments" class="mt-0">
                    Dirección del Despacho
                  </label>
                  <v-textarea
                    rows="4"
                    counter
                    outlined
                    hide-details
                    placeholder="Ingresa aquí la dirección del despacho"
                    v-model="form.address"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseModalDeliveryRequests"
          >
            <small>
              Cancelar
            </small>
          </v-btn>
          <v-btn
            @click="sendUpdateDeliveryRequestsOrMaterials"
            color="teal accent-4 text-white"
            small
            class="float-right"
          >
            Despachar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import moment from "moment";
import {
  TYPE_REQUESTS_DISPATCH,
  TYPE_REQUEST_TRANSFER,
  TYPE_REQUEST_RETREAT,
} from "@/constants/requestMaterial";
import { required } from "vuelidate/lib/validators";
import swal2 from "sweetalert2";
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalDeliveryRequests: {
      type: Function,
      default: () => {},
    },
    requestsToDelivery: {
      type: Array,
      default: () => [],
    },
    materialsToDelivery: {
      type: Array,
      default: () => [],
    },
  },
  validations: {
    form: {
      received_type: { required },
      user: { required },
      delivery_date: { required },
      delivery_time: { required },
    },
  },
  data() {
    return {
      TYPE_REQUESTS_DISPATCH,
      TYPE_REQUEST_TRANSFER,
      TYPE_REQUEST_RETREAT,
      datepicker_menu: false,
      timepicker_menu: false,
      loading: false,
      form: {
        received_type: null,
        user: null,
        delivery_date: moment().format("YYYY-MM-DD"),
        delivery_time: moment()
          .set({ minute: 0 })
          .format("HH:mm"),
        comment: "",
        address: "",
      },
      submitUpload: false,
    };
  },
  methods: {
    ...mapActions({
      dispatchRequestsDelivery: "supplying/dispatchRequestsDelivery",
      dispatchMaterialsDelivery: "supplying/dispatchMaterialsDelivery",
    }),
    async sendUpdateDeliveryRequestsOrMaterials() {
      let self = this;
      let resp = null;
      self.submitUpload = true;
      if (!self.$v.form.$invalid) {
        self.loading = true;
        let itemsToDelivery = self.requestsToDelivery.length
          ? self.requestsToDelivery
          : self.materialsToDelivery;
        let payload = {
          items: itemsToDelivery.map((item) => ({
            id: item.id,
            received_quantity: self.materialsToDelivery.length
              ? item.quantity
              : undefined,
            received_at: `${self.form.delivery_date} ${self.form.delivery_time}:00`,
            received_type: self.form.received_type,
            comment: self.form.comment ? self.form.comment : undefined,
            address: self.form.address ? self.form.address : undefined,
            user: self.requestsToDelivery.length ? self.form.user : undefined,
          })),
          user: self.materialsToDelivery.length ? self.form.user : undefined,
        };
        if (self.requestsToDelivery.length) {
          resp = await self.dispatchRequestsDelivery(payload);
        } else {
          resp = await self.dispatchMaterialsDelivery(payload);
        }
        if (!resp.status || resp.status === 400) {
          self.loading = false;
          return swal2.fire({
            icon: "error",
            title: "Error",
            text: resp.data.detail,
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 10000,
            timerProgressBar: true,
          });
        }
        if (resp.status == 200) {
          swal2.fire({
            icon: "success",
            title: "Excelente",
            text: `${
              self.requestsToDelivery.length
                ? "Solicitudes despachadas"
                : "Materiales despachados"
            } correctamente`,
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 5000,
            timerProgressBar: true,
          });
          this.$emit("requestsDispatches");
          this.closeModalDeliveryRequests(true);
          this.resetForm();
        }
        this.loading = false;
      }
    },
    executeCloseModalDeliveryRequests($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.resetForm();
        this.closeModalDeliveryRequests($event);
      }
    },
    resetForm() {
      this.form = {
        received_type: null,
        user: null,
        delivery_date: moment().format("YYYY-MM-DD"),
        delivery_time: moment()
          .set({ minute: 0 })
          .format("HH:mm"),
        comment: "",
        address: "",
      };
      this.submitUpload = false;
    },
  },
  computed: {
    ...authUsuarioComputed,
    delivery_date_formatted() {
      if (this.form.delivery_date) {
        return moment(this.form.delivery_date).format("DD/MM/YYYY");
      }
      return null;
    },
    minDateDelivery() {
      return moment().format("YYYY-MM-DD");
    },
  },
};
</script>

<style></style>
