<template>
  <section>
    <v-dialog
      v-model="modalAction"
      max-width="800px"
      scrollable
      @keydown="executeCloseModalDetailsDispatch"
      @click:outside="executeCloseModalDetailsDispatch"
    >
      <v-card>
        <div class="p-4 pb-0" v-if="currentItemDispatch">
          <h5>
            Detalles del despacho de
            {{ currentItemIsRequest ? "Solicitud" : "Material" }} COD:
            <b>{{
              currentItemIsRequest
                ? currentItemDispatch.sap_code
                : currentItemDispatch.material.code
            }}</b>
          </h5>
        </div>
        <hr />
        <v-card-text>
          <v-row
            v-if="currentItemDispatch"
            align-content="center"
            class="mb-2 mt-3"
          >
            <v-col cols="12" md="12" sm="12">
              <v-card-subtitle class="pb-0 font-weight-bold">
                Solicitador
              </v-card-subtitle>
              <v-card-text class="text--primary">
                <div>
                  {{ getRequestUserFromCurrentItemDispatch() }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-card-subtitle class="pb-0 font-weight-bold">
                Quien {{ TYPE_REQUEST_TRANSFER ? "Recibe" : "Retira" }}
              </v-card-subtitle>
              <v-card-text class="text--primary">
                <div>
                  {{
                    currentItemDispatch.updated_by
                      ? currentItemDispatch.updated_by
                      : "-"
                  }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="12" sm="12" v-if="currentItemIsRequest">
              <v-card-subtitle class="pb-0 font-weight-bold">
                Tipo de solicitud
              </v-card-subtitle>
              <v-card-text class="text--primary">
                <div>
                  {{
                    currentItemDispatch.request_type
                      ? currentItemDispatch.request_type.name
                      : "-"
                  }}
                </div>
              </v-card-text>
            </v-col>
            <v-col
              cols="12"
              md="12"
              sm="12"
              v-if="currentItemDispatch.received_type == TYPE_REQUEST_TRANSFER"
            >
              <v-card-subtitle class="pb-0 font-weight-bold">
                Dirección de envio
              </v-card-subtitle>
              <v-card-text class="text--primary">
                <div>
                  {{
                    currentItemDispatch.address
                      ? currentItemDispatch.address
                      : "-"
                  }}
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-card-subtitle class="pb-0 font-weight-bold">
                Observaciones
              </v-card-subtitle>
              <v-card-text class="text--primary">
                <div>
                  {{
                    currentItemDispatch.comment
                      ? currentItemDispatch.comment
                      : "-"
                  }}
                </div>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseModalDetailsDispatch"
          >
            <small>
              Cerrar
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import {
  TYPE_REQUEST_TRANSFER,
  TYPE_REQUEST_RETREAT,
} from "@/constants/requestMaterial";
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalDetailsDispatch: {
      type: Function,
      default: () => {},
    },
    currentItemDispatch: {
      default: null,
    },
  },
  data() {
    return {
      TYPE_REQUEST_TRANSFER,
      TYPE_REQUEST_RETREAT,
    };
  },
  methods: {
    executeCloseModalDetailsDispatch($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.closeModalDetailsDispatch($event);
      }
    },
    getRequestUserFromCurrentItemDispatch() {
      let indexRequestUser = this.currentItemDispatch.description.indexOf(
        "Solicitud"
      );
      if (indexRequestUser > 0) {
        return this.currentItemDispatch.description.substring(indexRequestUser);
      }
      return "-";
    },
  },
  computed: {
    currentItemIsRequest() {
      return this.currentItemDispatch?.sap_code;
    },
  },
};
</script>

<style></style>
