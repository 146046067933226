<template>
  <section>
    <v-dialog
      v-model="modalAction"
      max-width="1200px"
      scrollable
      @keydown="executeCloseModalMaterialsRequestTransfer"
      @click:outside="executeCloseModalMaterialsRequestTransfer"
    >
      <v-card v-if="currentRequestTransfer">
        <div class="p-4 pb-0">
          <h5>
            COD Solicitud:
            <b>
              {{ currentRequestTransfer.sap_code }}
            </b>
            | Tipo de solicitud:
            <b>
              {{ currentRequestTransfer.request_type.name }}
            </b>
          </h5>
        </div>
        <hr />
        <v-card-text>
          <v-container class="pt-0">
            <div class="d-flex align-items-center justify-content-between">
              <section class="w-25">
                <v-text-field
                  v-model="filters.filter"
                  class="px-2"
                  clearable
                  placeholder="Buscar:"
                ></v-text-field>
              </section>
              <h5 class="mt-3 mx-3" v-if="actionDispatch">
                Seleccionados:
                {{ materialsSelected.length }}
              </h5>
            </div>
            <div class="d-flex justify-content-end mb-4" v-if="actionDispatch">
              <v-btn
                :disabled="!materialsSelected.length"
                @click="openModalDeliveryMaterials"
                color="teal accent-4 text-white"
                small
              >
                <v-icon small class="mr-1">
                  mdi-truck-outline
                </v-icon>
                Despachar materiales
              </v-btn>
            </div>
            <v-data-table
              class="px-2 elevation-4 pt-2"
              v-model="materialsSelected"
              :headers="tableHeaders"
              :items="filteredMaterials"
              :items-per-page="200"
              hide-default-footer
              loading-text="Cargando materiales"
              item-key="id"
              no-data-text="Sin materiales"
              :show-select="actionDispatch"
              @page-count="pageCount = $event"
            >
              <template v-slot:item.code="{ item }">
                <div class="d-flex justify-content-start">
                  <v-chip label small color="primary" class="mt-0 text-white">
                    {{ item.material.code }}
                  </v-chip>
                </div>
              </template>
              <template v-slot:item.name="{ item }">
                <p class="text-left my-0" style="font-size: 11px">
                  {{ item.material.name }}
                </p>
              </template>
              <template v-slot:item.received_type="{ item }">
                <p class="text-center my-0" style="font-size: 12px">
                  {{
                    item.received_type
                      ? item.received_type
                      : currentRequestTransfer.received_type
                      ? currentRequestTransfer.received_type
                      : "-"
                  }}
                </p>
              </template>
              <template v-slot:item.unit="{ item }">
                <p class="text-center my-0" style="font-size: 11px">
                  {{ item.material.measurement_unit }}
                </p>
              </template>
              <template v-slot:item.quantity="{ item }">
                <p class="text-right mt-4" style="font-size: 11px">
                  {{ item.quantity }}
                </p>
              </template>
              <template v-slot:item.received_at="{ item }">
                <span class="mt-2" style="font-size: 12px;">
                  <span v-if="item.received_at" class="text-success">
                    {{
                      moment(item.received_at).format("DD/MM/YYYY HH:mm [hrs]")
                    }}
                  </span>
                  <span
                    v-else-if="currentRequestTransfer.received_at"
                    class="text-success"
                  >
                    {{
                      moment(currentRequestTransfer.received_at).format(
                        "DD/MM/YYYY HH:mm [hrs]"
                      )
                    }}
                  </span>
                  <v-chip v-else color="warning" small>
                    <small>
                      Sin Definir
                    </small>
                  </v-chip>
                </span>
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="d-flex align-items-center justify-content-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="openModalDetailsDispatch(item)"
                        icon
                        :disabled="
                          !item.received_at &&
                            !currentRequestTransfer.received_at
                        "
                        :color="
                          item.received_at || currentRequestTransfer.received_at
                            ? 'primary'
                            : 'warning'
                        "
                        v-bind="attrs"
                        v-on="on"
                        x-small
                        class="mx-3"
                      >
                        <v-icon>
                          mdi-truck
                        </v-icon>
                      </v-btn>
                    </template>
                    <span
                      >Ver detalles del despacho
                      {{
                        !item.received_at && !currentRequestTransfer.received_at
                          ? "(Sin Despachar)"
                          : ""
                      }}</span
                    >
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseModalMaterialsRequestTransfer"
          >
            <small>
              Cerrar
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import moment from "moment";
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalMaterialsRequestTransfer: {
      type: Function,
      default: () => {},
    },
    currentRequestTransfer: {
      default: null,
    },
    actionDispatch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      moment,
      currentMaterials: [],
      materialsSelected: [],
      filters: {
        filter: "",
      },
      tableHeaders: [
        {
          text: "Acción",
          align: "center",
          value: "actions",
          width: "4%",
          sortable: false,
        },
        {
          text: "Código Material",
          align: "start",
          sortable: false,
          value: "code",
          width: "12%",
        },
        {
          text: "Descripción",
          class: "text-left",
          sortable: false,
          value: "name",
        },
        {
          text: "Despacho o Retiro",
          align: "center",
          sortable: false,
          value: "received_type",
        },
        {
          text: "Cantidad",
          align: "center",
          sortable: false,
          width: "10%",
          value: "quantity",
        },
        {
          text: "Unidad",
          align: "center",
          sortable: false,
          value: "unit",
        },
        {
          text: "Fecha entrega",
          align: "center",
          sortable: false,
          value: "received_at",
        },
      ],
    };
  },
  methods: {
    ...mapActions({}),
    executeCloseModalMaterialsRequestTransfer($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.currentMaterials = [];
        this.closeModalMaterialsRequestTransfer($event);
      }
    },
    setMaterialsCurrentRequest(materials) {
      this.currentMaterials = materials;
    },
    openModalDetailsDispatch(material) {
      let itemDispatch = material.received_at
        ? {
            ...material,
            description: this.currentRequestTransfer.description,
          }
        : {
            ...material,
            description: this.currentRequestTransfer.description,
            received_at: this.currentRequestTransfer.received_at,
            received_type: this.currentRequestTransfer.received_type,
            user: this.currentRequestTransfer.user,
            comment: this.currentRequestTransfer.comment,
            address: this.currentRequestTransfer.address,
            updated_by: this.currentRequestTransfer.updated_by,
          };
      this.$emit("openModalDetailsDispatch", itemDispatch);
    },
    openModalDeliveryMaterials() {
      this.$emit("openModalDeliveryMaterials", [...this.materialsSelected]);
      this.materialsSelected = [];
    },
  },
  computed: {
    ...authUsuarioComputed,
    filteredMaterials() {
      if (this.filters.filter) {
        let filter = this.filters.filter.trim().toLowerCase();
        return this.currentMaterials.filter(({ material }) => {
          return (
            material.code.toLowerCase().includes(filter) ||
            material.name.toLowerCase().includes(filter) ||
            material.measurement_unit.toLowerCase().includes(filter)
          );
        });
      }
      return this.currentMaterials;
    },
  },
  watch: {
    currentRequestTransfer(newRequest) {
      if (newRequest) {
        this.setMaterialsCurrentRequest(newRequest.items);
      }
    },
  },
};
</script>

<style></style>
