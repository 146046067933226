<template>
  <v-container class="p-3 mt-5">
    <section class="d-flex flex-column flex-sm-row justify-content-between">
      <h2 class="ml-1 my-2">
        Solicitud de Transporte / Retiro
      </h2>
      <v-breadcrumbs :items="breadcrumb"></v-breadcrumbs>
    </section>
    <v-row align-content="start" align-md="center" class="mt-1 mb-5">
      <v-col cols="12" md="4" sm="12" class="pb-0">
        <filter-project
          label="Proyecto"
          :projects="
            projects.items
              ? projects.items.filter((item) => item.warehouse)
              : []
          "
          :getProjectsFunction="getProjects"
          @cleanProject="cleanCurrentProject"
          @inputProject="
            updateCurrentProject($event);
            getRequestsDeliveryData(filters.page);
          "
        ></filter-project>
      </v-col>
      <v-col cols="12" md="4" sm="12" class="pb-0">
        <v-text-field
          v-model="filters.filter"
          @input="getRequestsDeliveryData(1)"
          clearable
          placeholder="Buscar:"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" sm="12" class="pb-0">
        <v-btn
          :disabled="!requestsSelected.length || filters.project_code"
          @click="openModalDeliveryRequests"
          color="teal accent-4 text-white"
          small
          class="float-right"
        >
          <v-icon small class="mr-1">
            mdi-truck-outline
          </v-icon>
          Despachar
        </v-btn>
      </v-col>
    </v-row>
    <div
      class="d-flex align-items-center justify-content-between flex-column flex-sm-row mb-1"
    >
      <p class="text-info font-weight-bold text-right">
        <v-icon color="blue">
          mdi-information-outline
        </v-icon>
        Selecciona las solicitudes a despachar
      </p>
      <h5 class="my-0">
        Seleccionados:
        {{ requestsSelected.length }}
      </h5>
    </div>
    <v-data-table
      class="px-1 elevation-3"
      v-model="requestsSelected"
      :headers="tableHeaders"
      :items="wrappedRequestsTransfer"
      :items-per-page="10"
      :page.sync="filters.page"
      hide-default-footer
      :loading="loadingRequestsDelivery"
      loading-text="Cargando solicitudes de traslado"
      show-select
      item-key="id"
      no-data-text="Sin solicitudes de traslado"
    >
      <template v-slot:item.project="{ item }">
        <p class="text-left my-0">
          {{ item.project.code }}
        </p>
      </template>
      <template v-slot:item.sap_code="{ item }">
        <p class="text-left my-0">
          {{ item.sap_code }}
        </p>
      </template>
      <template v-slot:item.received_type="{ item }">
        <p class="text-center my-0" style="font-size: 11px">
          {{ item.received_type ? item.received_type : "-" }}
        </p>
      </template>

      <template v-slot:item.stage_code="{ item }">
        <p class="my-0">
          {{ item.stage_code ? item.stage_code : "-" }}
        </p>
      </template>
      <template v-slot:item.created_at="{ item }">
        <span class="mt-2" style="font-size: 11x;">
          {{ new Date(item.created_at).toLocaleDateString() }}
        </span>
      </template>
      <template v-slot:item.received_at="{ item }">
        <span class="mt-2" style="font-size: 12px;">
          <span v-if="item.received_at" class="text-success">
            {{ moment(item.received_at).format("DD/MM/YYYY HH:mm [hrs]") }}
          </span>
          <v-chip v-else color="warning" small>
            <small>
              Sin Definir
            </small>
          </v-chip>
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-items-center justify-content-center">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="openModalMaterialsRequestTransfer(item)"
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                x-small
              >
                <v-icon>
                  mdi-view-list-outline
                </v-icon>
              </v-btn>
            </template>
            <span>Ver materiales de la solicitud</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="openModalDetailsDispatch(item)"
                icon
                :disabled="!item.received_at"
                :color="item.received_at ? 'primary' : 'warning'"
                v-bind="attrs"
                v-on="on"
                x-small
                class="mx-3"
              >
                <v-icon>
                  mdi-truck
                </v-icon>
              </v-btn>
            </template>
            <span
              >Ver detalles del despacho
              {{ !item.received_at ? "(Sin Despachar)" : "" }}</span
            >
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <div class="my-3 float-right">
      <v-pagination
        total-visible="8"
        v-model="filters.page"
        :length="
          requestsDelivery.number_of_pages
            ? requestsDelivery.number_of_pages
            : 1
        "
      ></v-pagination>
    </div>
    <modal-delivery-requests
      :modalAction="dialogDeliveryRequess"
      :closeModalDeliveryRequests="closeModalDeliveryRequests"
      :requestsToDelivery="requestsSelected"
      :materialsToDelivery="materialsToDispatch"
      @requestsDispatches="
        getRequestsDeliveryData(1);
        closeModalMaterialsRequestTransfer();
      "
    ></modal-delivery-requests>
    <modal-materials-request-transfer
      :modalAction="dialogMaterialsRequestTransfer"
      :actionDispatch="false"
      :currentRequestTransfer="currentRequestTransfer"
      :closeModalMaterialsRequestTransfer="closeModalMaterialsRequestTransfer"
      @openModalDetailsDispatch="openModalDetailsDispatch"
      @openModalDeliveryMaterials="
        setMaterialsToDispatchAndOpenModalDeliveryRequests
      "
    >
    </modal-materials-request-transfer>
    <modal-details-dispatch
      :modalAction="dialogDetailsDispatch"
      :closeModalDetailsDispatch="closeModalDetailsDispatch"
      :currentItemDispatch="currentItemDetailsDispatch"
    >
    </modal-details-dispatch>
  </v-container>
</template>

<script>
import moment from "moment";
import FilterProject from "@/components/commons/projects/FilterProject.vue";
import { mapActions, mapGetters } from "vuex";
import ModalDeliveryRequests from "./ModalDeliveryRequests.vue";
import ModalMaterialsRequestTransfer from "./ModalMaterialsRequestTransfer.vue";
import ModalDetailsDispatch from "./ModalDetailsDispatch.vue";
export default {
  components: {
    FilterProject,
    ModalDeliveryRequests,
    ModalMaterialsRequestTransfer,
    ModalDetailsDispatch,
  },
  mounted() {
    this.getRequestsDeliveryData();
  },
  beforeDestroy() {
    this.clearDeliveryRequests();
  },
  data() {
    return {
      moment,
      breadcrumb: [
        {
          text: "Proyectos",
          disabled: false,
          href: "/",
        },
        {
          text: "Solicitud de Traslado / Retiro",
          disabled: true,
          href: "/supplying/requests-transfer/date-delivery",
        },
      ],
      filters: {
        filter: "",
        page: 1,
      },
      currentProject: null,
      dialogDeliveryRequess: false,
      dialogMaterialsRequestTransfer: false,
      dialogDetailsDispatch: false,
      loadingRequestsDelivery: false,
      debounceRequests: false,
      debounceRequestsTime: 500,
      requestsSelected: [],
      materialsToDispatch: [],
      currentRequestTransfer: null,
      currentItemDetailsDispatch: null,
      tableHeaders: [
        {
          text: "Acción",
          align: "center",
          value: "actions",
          width: "4%",
          sortable: false,
        },
        {
          text: "Despacho o Retiro",
          align: "center",
          sortable: false,
          value: "received_type",
        },
        {
          text: "Projecto",
          align: "start",
          sortable: false,
          value: "project",
        },
        {
          text: "Etapa proyecto",
          align: "start",
          sortable: false,
          value: "stage_code",
          width: "9%",
        },
        {
          text: "Código",
          align: "start",
          sortable: false,
          value: "sap_code",
        },
        {
          text: "Tipo de solicitud",
          align: "center",
          sortable: false,
          value: "request_type.name",
        },
        {
          text: "Bodega origen",
          align: "center",
          sortable: false,
          value: "origin_warehouse",
        },
        {
          text: "Bodega destino",
          align: "center",
          sortable: false,
          value: "destination_warehouse",
        },
        {
          text: "Fecha de creación",
          align: "center",
          sortable: false,
          value: "created_at",
        },
        {
          text: "Fecha de entrega",
          align: "center",
          sortable: false,
          value: "received_at",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getProjects: "requestMaterialsSap/getProjects",
      getRequestsDelivery: "supplying/getRequestsDelivery",
      clearDeliveryRequests: "supplying/clearDeliveryRequests",
    }),
    async getRequestsDeliveryData(page = 1) {
      clearTimeout(this.debounceRequests);
      this.debounceRequests = setTimeout(async () => {
        this.loadingRequestsDelivery = true;
        await this.getRequestsDelivery({
          ...this.filters,
          page: page,
          filter: this.filters.filter ? this.filters.filter : undefined,
          project_filter: this.currentProject
            ? this.currentProject.split(" - ")[0]
            : undefined,
        });
        this.loadingRequestsDelivery = false;
      }, this.debounceRequestsTime);
    },
    updateCurrentProject(project_code) {
      this.currentProject = project_code;
    },
    cleanCurrentProject() {
      this.currentProject = null;
    },
    openModalDeliveryRequests() {
      this.dialogDeliveryRequess = true;
    },
    setMaterialsToDispatchAndOpenModalDeliveryRequests(materials) {
      this.materialsToDispatch = materials;
      this.openModalDeliveryRequests();
    },
    closeModalDeliveryRequests() {
      this.requestsSelected = [];
      this.materialsToDispatch = [];
      this.dialogDeliveryRequess = false;
    },
    openModalMaterialsRequestTransfer(requestTransfer) {
      this.currentRequestTransfer = requestTransfer;
      this.dialogMaterialsRequestTransfer = true;
    },
    closeModalMaterialsRequestTransfer() {
      this.currentRequestTransfer = null;
      this.dialogMaterialsRequestTransfer = false;
    },
    openModalDetailsDispatch(itemDispatch) {
      this.currentItemDetailsDispatch = itemDispatch;
      this.dialogDetailsDispatch = true;
    },
    closeModalDetailsDispatch() {
      this.currentItemDetailsDispatch = null;
      this.dialogDetailsDispatch = false;
    },
  },
  computed: {
    ...mapGetters({
      projects: "requestMaterialsSap/projects",
      requestsDelivery: "supplying/requestsDelivery",
    }),
    wrappedRequestsTransfer() {
      return this.requestsDelivery.items;
    },
  },
  watch: {
    "filters.page": function(page) {
      this.getRequestsDeliveryData(page);
    },
  },
};
</script>

<style></style>
